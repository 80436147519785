import { Flex, Heading, Text } from "@chakra-ui/react";

export default function Header() {
  return (
    <Flex
      direction={"column"}
      position="relative"
      bg={"transparent"}
      w={"100%"}
      pt={"34px"}
      pb={"64px"}
    >
      {/* sub-heading */}
      <Text
        fontFamily={"Poppins, sans-serif"}
        fontWeight={"600"}
        bgGradient={`linear(to right, #641ba3 0%, #2088be 100%)`}
        bgClip={"text"}
        color={"transparent"}
        textTransform={"uppercase"}
        letterSpacing={"1px"}
        fontSize={{ lg: "14px", "2xl": "16px" }}
        lineHeight={1}
        w={"fit-content"}
      >
        Contacts
      </Text>

      {/* Section Heading */}
      <Heading
        mb={"34px"}
        as={"h2"}
        fontFamily={"Poppins, sans-serif"}
        color={"#202053"}
        fontSize={{ lg: "48px", xl: "56px", md: "36px", sm: "28px" }}
        fontWeight={"800"}
        lineHeight={{ lg: "66px", "2xl": "76px", md: "56px", sm: "46px" }}
      >
        Data Breach Contact List
      </Heading>
      <Text>
        If you think your data protection rights have not been respected, you
        can make a complaint directly to your national data protection authority
        which will investigate your complaint and give you a response within 3
        months.
      </Text>
    </Flex>
  );
}
