import { ColumnOrderState } from "@tanstack/react-table";
import { ChangeEvent, DragEvent } from "react";
import { FormDataProps } from "./Review";
import { environment } from "environments";
import { AxiosInstance } from "axios";

export const handleDragStart = (
  e: React.DragEvent<HTMLTableCellElement>,
  headerId: string
) => {
  e.dataTransfer.setData("text/plain", headerId);
  e.currentTarget.style.backgroundColor = "#dce0e5";
};

export const handleDrop = (
  e: React.DragEvent<HTMLTableCellElement>,
  targetHeaderId: string,
  columnOrder: ColumnOrderState,
  setColumnOrder: React.Dispatch<React.SetStateAction<ColumnOrderState>>
) => {
  e.preventDefault();
  const draggedHeaderId = e.dataTransfer.getData("text/plain");
  const newColumnOrder = [...columnOrder];
  const draggedIndex = newColumnOrder.indexOf(draggedHeaderId);
  const targetIndex = newColumnOrder.indexOf(targetHeaderId);

  newColumnOrder.splice(draggedIndex, 1);
  newColumnOrder.splice(targetIndex, 0, draggedHeaderId);
  setColumnOrder(newColumnOrder);
  e.currentTarget.style.border = "";
};

export const handleDragEnd = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.currentTarget.style.backgroundColor = "";
};

export const handleDragOver = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.preventDefault();
  e.currentTarget.style.border = "2px dashed #cbd5e0";
};

export const handleDragLeave = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.currentTarget.style.border = "";
};

export const handleFileChange = (
  e: ChangeEvent<HTMLInputElement>,
  handleFileValidation: (files: FileList | null) => void
) => {
  e.preventDefault();
  handleFileValidation(e.target.files);
};

export const handleDropFiles = (
  e: DragEvent,
  setDragActive: React.Dispatch<React.SetStateAction<boolean>>,
  handleFileValidation: (files: FileList | null) => void
) => {
  e.preventDefault();
  setDragActive(false);
  handleFileValidation(e.dataTransfer?.files);
};

export const handleDragFiles = (
  e: DragEvent,
  setDragActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  e.preventDefault();
  setDragActive(e.type === "dragenter" || e.type === "dragover");
};

export const handleFileValidation = (
  files: FileList | null,
  formData: FormDataProps,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>
) => {
  if (!files) return;

  const newFiles = Array.from(files).filter(
    (file) => file.type === "application/pdf"
  );

  const currentFiles = formData.files.value;
  const filesToProcess = newFiles;
  let error = "";
  let validFiles: File[] = [];

  for (const file of filesToProcess) {
    if (
      currentFiles.some(
        (existingFile: { name: string }) => existingFile.name === file.name
      )
    ) {
      error = "File already exists";
      continue;
    } else {
      validFiles.push(file);
    }
  }

  setFormData((prev: any) => ({
    ...prev,
    files: {
      ...prev.files,
      value: [...validFiles],
      invalid: !!error,
      error,
    },
  }));
};

export const handleViewFile = (file: File) => {
  return file;
};
export const handleRemoveFile = (
  fileName: string,
  formData: FormDataProps,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>
) => {
  setFormData((prev: any) => {
    const updatedFiles = prev.files.value.filter(
      (file: any) => file.name !== fileName
    );
    return {
      ...prev,
      files: {
        ...prev.files,
        value: updatedFiles,
        invalid: false,
        error: "",
      },
    };
  });
};

export const clearForm = (
  setUploadProgress: React.Dispatch<React.SetStateAction<number>>,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>
) => {
  setFormData({
    files: { value: [], invalid: false, error: "" },
    user_id: "",
  });
  setUploadProgress(0);
};

export const handleUploadFiles = async (
  axiosPrivate: AxiosInstance,
  formData: FormDataProps,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>,
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmittedForm: React.Dispatch<React.SetStateAction<string>>,
  setUploadProgress: React.Dispatch<React.SetStateAction<number>>,
  toast: (options: any) => void,
  MAX_TOTAL_SIZE: number,
  agreedToPrivacy: boolean
) => {
  setSubmitting(true);

  if (formData.files.value.length === 0) {
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        invalid: formData.files.value.length === 0,
        error:
          formData.files.value.length === 0
            ? "Please upload at least one file"
            : "",
      },
    }));
    setSubmitting(false);
    return;
  }

  if (!agreedToPrivacy) {
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        invalid: true,
        error: "Please accept and agree to the privacy policy",
      },
    }));
    setSubmitting(false);
    return;
  }

  const totalSize =
    formData.files.value.reduce((acc, file) => acc + file.size, 0) /
    (1024 * 1024); // size in MB

  if (totalSize > MAX_TOTAL_SIZE) {
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        invalid: true,
        error: "Total file size exceeds 10MB.",
      },
    }));
    setSubmitting(false);
    return;
  }

  const data = new FormData();
  formData.files.value.forEach((file) => data.append("files", file));
  data.append("user_id", String(formData.user_id));

  try {
    const res = await axiosPrivate.post(
      `${environment.BACKEND_API}/api/v1/upload_form`,
      data,
      {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          if (total) {
            const progress = Math.round((loaded / total) * 100);
            setUploadProgress(progress);
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    toast({
      description: "Form submitted successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    setSubmitted(true);
    setUploadProgress(0);
    setSubmittedForm(res?.data?.data[0]);
    clearForm(setUploadProgress, setFormData);
  } catch (err: any) {
    toast({
      description: err.response.data.message
        ? err.response.data.message
        : "Failed to upload files. Please try again.",
      status: "warning",

      duration: 3000,
      isClosable: true,
    });
    setUploadProgress(0);
    clearForm(setUploadProgress, setFormData);
  } finally {
    setSubmitting(false);
    setUploadProgress(0);
    clearForm(setUploadProgress, setFormData);
  }
};
