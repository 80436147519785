import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
}

function LaunchingSoon({ text }: Props) {
  // Hook
  const { t } = useTranslation();

  if (!text) return <></>;

  return (
    <Flex
      align={"center"}
      justify={"center"}
      position={"absolute"}
      top={text === "stealth1" || text === "stealth2" ? "-50px" : "unset"}
      bottom={text === "stealth3" || text === "stealth4" ? "-50px" : "unset"}
      left={"calc(50% -100px)"}
      bg={"white"}
      py={3}
      borderRadius={"12px"}
      w={"140px"}
      boxShadow={"md"}
      fontSize={"13px"}
      fontFamily={"Poppins, sans-serif"}
    >
      {t("brands.soon")}
    </Flex>
  );
}

export default LaunchingSoon;
