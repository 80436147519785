import { useEffect, useMemo } from "react";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import logoImg from "assets/home/header/logo.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { GoSignOut } from "react-icons/go";

import useLogout from "hooks/useLogout";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/authSlice";

import { useTranslation } from "react-i18next";

import ukFlag from "assets/translation/uk.svg";
import deFlag from "assets/translation/de.svg";

interface ItemProps {
  id: string;
  name: string;
  link: string;
}

interface NavItemProps {
  item: ItemProps;
}

const lngs = {
  en: { nativeName: "English" },
  de: { nativeName: "German" },
} as const; // NOTE: readonly to ensure proper type inference

function Nav() {
  const { user } = useSelector(selectCurrentAuthData);

  // Hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const _preferredLang = localStorage.getItem("preferredLang");
    const preferredLang = _preferredLang ? JSON.parse(_preferredLang) : "en";
    if (i18n.language !== preferredLang) i18n.changeLanguage(preferredLang);
  }, [i18n]);

  // APIs
  const { logout, loggingOut } = useLogout();

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 1024px)");

  // Handlers
  async function handleLogout() {
    await logout();
  }

  const navItems: ItemProps[] = [
    { id: "home", name: t("nav.item1"), link: "/" },
    { id: "investors", name: t("nav.item2"), link: "/investor-relations" },
  ];

  const updateLanguange = (langKey: string) => {
    i18n.changeLanguage(langKey);
    localStorage.setItem("preferredLang", JSON.stringify(langKey));
  };

  return (
    <Flex
      as={"header"}
      position={"fixed"}
      w={"85%"}
      h={"60px"}
      top={"25px"}
      left={0}
      right={0}
      mx={"auto"}
      bg={"rgba(240, 242, 245, 0.9)"}
      align={"center"}
      justify={"space-between"}
      px={6}
      borderRadius={"30px"}
      boxShadow={"md"}
      backdropFilter={"blur(8px)"}
      zIndex={10}
    >
      {/* logo + name */}
      <Flex
        cursor="pointer"
        align={"center"}
        gap={2}
        onClick={() => navigate("/")}
      >
        {/* logo */}
        <Image src={logoImg} alt="Empathic AI" w={"32px"} objectFit={"cover"} />

        {/* name */}
        <Text
          fontWeight={700}
          fontSize={"18px"}
          style={{
            background: "linear-gradient(90deg, #323383 0.5%, #2088BE 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          EmpathicAI
        </Text>
      </Flex>

      <Flex align={"center"} gap={4}>
        {/* nav items */}
        {isMobileView ? (
          // nav menu for small devices <992px
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<RxHamburgerMenu />}
              variant="outline"
            />
            <MenuList>
              <MenuItem onClick={() => navigate("/")}>
                {t("nav.item1")}
              </MenuItem>
              <MenuItem onClick={() => navigate("/investor-relations")}>
                {t("nav.item2")}
              </MenuItem>
            </MenuList>
          </Menu>
        ) : (
          // nav menu for large screens devices >991px
          <Flex
            as={"ul"}
            gap={2}
            h={"100%"}
            w={"fit-content"}
            align={"center"}
            justify={"center"}
            listStyleType="none"
            margin="0"
            padding="0"
          >
            {navItems.map((item) => (
              <NavItem item={item} key={item?.id} />
            ))}

            {user && (
              <Button
                variant={"link"}
                w={"fit-content"}
                h={"fit-content"}
                fontSize={{ lg: "13px", xl: "14px" }}
                fontWeight={"400"}
                textTransform={"capitalize"}
                color={"#202053"}
                bg={"transparent"}
                letterSpacing={0}
                p={0}
                display={"flex"}
                alignItems={"center"}
                _focus={{ opacity: 0.8 }}
                _focusWithin={{ opacity: 1 }}
                _active={{ opacity: 1 }}
                isLoading={loggingOut}
                loadingText={t("nav.item4")}
                transition={"all .3s ease"}
                onClick={() => handleLogout()}
                _hover={{ textDecoration: "none", color: "#2088BE" }}
                leftIcon={
                  <Icon
                    fontSize={{ base: "xs", md: "sm", lg: "md" }}
                    as={GoSignOut}
                  />
                }
                iconSpacing={1}
              >
                {t("nav.item3")}
              </Button>
            )}
          </Flex>
        )}

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            w={"fit-content"}
            h={"fit-content"}
            minW={"fit-content"}
            borderRadius={"50%"}
            p={0}
            icon={
              <Box
                as="img"
                src={i18n.resolvedLanguage === "en" ? ukFlag : deFlag}
                alt="Flag"
                boxSize={"24px"}
              />
            }
            variant="outline"
          />

          <MenuList minW={"fit-content"}>
            {Object.keys(lngs).map((lng) => {
              const langKey = lng as keyof typeof lngs;

              return (
                <MenuItem
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  key={langKey}
                  w={"fit-content"}
                  minW={"fit-content"}
                  maxW={"fit-content"}
                >
                  <Button
                    border={"none"}
                    outline={"none"}
                    fontSize={"14px"}
                    w={"fit-content"}
                    minW={"fit-content"}
                    maxW={"fit-content"}
                    color={
                      i18n.resolvedLanguage === langKey ? "#ac145a" : "#242b3b"
                    }
                    bg={
                      i18n.resolvedLanguage === langKey
                        ? "rgba(172, 20, 90, 0.12)"
                        : "transparent"
                    }
                    type="submit"
                    onClick={() => updateLanguange(langKey)}
                  >
                    <Flex align={"center"} gap={2}>
                      <Flex
                        w={4}
                        h={4}
                        backgroundImage={langKey === "en" ? ukFlag : deFlag}
                        bgSize={"cover"}
                      />
                      {lngs[langKey]?.nativeName}
                    </Flex>
                  </Button>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}

export default Nav;

function NavItem({ item }: NavItemProps) {
  const { name, link, id } = item;

  // Hooks
  const location = useLocation();

  // active route
  const currentRoute = useMemo(
    () => location?.pathname?.toLowerCase() === link?.toLowerCase(),
    [location, link]
  );

  return (
    <Link
      as={RouterLink}
      to={link}
      id={id}
      p={2.5}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      letterSpacing={currentRoute ? "-0.012rem" : 0}
      border={"none"}
      h={"100%"}
      color={currentRoute ? "#2088BE" : "#202053"}
      fontWeight={currentRoute ? "500" : "400"}
      _hover={{
        textDecoration: "none",
        color: currentRoute ? "#2088BE" : "#202053",
      }}
    >
      <Text fontSize={{ lg: "13px", xl: "14px" }} textTransform={"capitalize"}>
        {name}
      </Text>
    </Link>
  );
}
