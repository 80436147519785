export const ContactList = [
  {
    name: { title: "Nigeria", code: "ng" },
    institution: {
      location: "Garki, Abuja Nigeria",
      website: "nitda.gov.ng",
      websiteLink: "https://nitda.gov.ng",
      title: "The National Information Technology Development Agency",
    },
    contact: {
      tel: ["+2348168401851"],
      fax: [],
      email: ["info@nitda.gov.ng"],
    },
  },
  {
    name: { title: "Kenya", code: "ke" },
    institution: {
      location: "",
      website: "",
      websiteLink: "",
      title:
        "The Office of the Data Protection Commissioner. Ministry of Information, Communications and Telecommunication",
    },
    contact: {
      tel: ["+254 020 4920000 / 1"],
      fax: [],
      email: ["info@information.go.ke"],
    },
  },
  {
    name: { title: "South Africa", code: "za" },
    institution: {
      location: "",
      website: "",
      title: "Office of the Information Regulator",
    },
    contact: {
      tel: ["+27 (0) 10 023 5200", "+27 (0) 82 746 4173"],
      fax: [],
      email: ["complaints.IR@justice.gov.za"],
    },
  },
  {
    name: { title: "Qatar", code: "qa" },
    institution: {
      location: "",
      website: "",
      websiteLink: "",
      title: "Compliance and Data Protection (CDP) Department",
    },
    contact: {
      tel: ["+974 4406 9991"],
      fax: [],
      email: ["cdp@motc.gov.qa"],
    },
  },
  {
    name: { title: "Sweden", code: "se" },
    institution: {
      location: "",
      website: "www.datainspektionen.se",
      websiteLink: "http://www.datainspektionen.se/",
      title: "Datainspektionen",
    },
    contact: {
      tel: ["+46 8 657 6100"],
      fax: ["+46 8 652 8652"],
      email: ["datainspektionen@datainspektionen.se"],
    },
  },
  {
    name: { title: "Uganda", code: "ug" },
    institution: {
      location: "",
      website: "www.ict.go.ug",
      websiteLink: "https://www.ict.go.ug",
      title: "Ministry of Information and Communications Technology",
    },
    contact: {
      tel: ["+256 (414) 236 262"],
      fax: [],
      email: ["webmaster@ict.go.ug"],
    },
  },
  {
    name: { title: "Austria", code: "at" },
    institution: {
      location: "",
      website: "www.dsb.gv.at",
      websiteLink: "http://www.dsb.gv.at",
      title: "Österreichische Datenschutzbehörde",
    },
    contact: {
      tel: ["+43 1 531 15 202525"],
      fax: ["+43 1 531 15 202690"],
      email: ["dsb@dsb.gv.at"],
    },
  },
  {
    name: { title: "Belgium", code: "be" },
    institution: {
      location: "",
      website: "www.privacycommission.be",
      websiteLink: "http://www.privacycommission.be/",
      title: "Commission de la protection de la vie privée",
    },
    contact: {
      tel: ["+32 2 274 48 00"],
      fax: ["+32 2 274 48 35"],
      email: ["commission@privacycommission.be"],
    },
  },
  {
    name: { title: "Bulgaria", code: "bg" },
    institution: {
      location: "",
      website: "www.cpdp.bg",
      websiteLink: "http://www.cpdp.bg/",
      title: "Commission for Personal Data Protection",
    },
    contact: {
      tel: ["+359 2 915 3580"],
      fax: ["+359 2 915 3525"],
      email: ["kzld@cpdp.bg"],
    },
  },
  {
    name: { title: "Croatia", code: "hr" },
    institution: {
      location: "",
      website: "www.azop.hr",
      websiteLink: "http://www.azop.hr/",
      title: "Croatian Personal Data Protection Agency",
    },
    contact: {
      tel: ["+385 1 4609 000"],
      fax: ["+385 1 4609 099"],
      email: ["info@azop.hr", "azop@azop.hr"],
    },
  },
  {
    name: { title: "Cyprus", code: "cy" },
    institution: {
      location: "",
      website: "www.dataprotection.gov.cy",
      websiteLink: "http://www.dataprotection.gov.cy/",
      title: "Commissioner for Personal Data Protection",
    },
    contact: {
      tel: ["+357 22 818 456"],
      fax: ["+357 22 304 565"],
      email: ["jcommissioner@dataprotection.gov.cy"],
    },
  },
  {
    name: { title: "Czech Republic", code: "cz" },
    institution: {
      location: "",
      website: "www.uoou.cz",
      websiteLink: "http://www.uoou.cz/",
      title: "The Office for Personal Data Protection",
    },
    contact: {
      tel: ["+420 234 665 111"],
      fax: ["+420 234 665 444"],
      email: ["posta@uoou.cz"],
    },
  },
  {
    name: { title: "Denmark", code: "dk" },
    institution: {
      location: "",
      website: "www.datatilsynet.dk",
      websiteLink: "http://www.datatilsynet.dk/",
      title: "Datatilsynet ",
    },
    contact: {
      tel: ["+45 33 1932 00"],
      fax: ["+45 33 19 32 18"],
      email: ["dt@datatilsynet.dk"],
    },
  },
  {
    name: { title: "Estonia", code: "ee" },
    institution: {
      location: "",
      website: "www.aki.ee/en",
      websiteLink: "http://www.aki.ee/en",
      title: "Estonian Data Protection Inspectorate (Andmekaitse Inspektsioon)",
    },
    contact: {
      tel: ["+372 6274 135"],
      fax: ["+372 6274 137"],
      email: ["info@aki.ee"],
    },
  },
  {
    name: { title: "Finland", code: "fi" },
    institution: {
      location: "",
      website: "www.tietosuoja.fi/en",
      websiteLink: "http://www.tietosuoja.fi/en/",
      title: "Office of the Data Protection Ombudsman",
    },
    contact: {
      tel: ["+358 10 3666 700"],
      fax: ["+358 10 3666 735"],
      email: ["tietosuoja@om.fi"],
    },
  },
  {
    name: { title: "France", code: "fr" },
    institution: {
      location: "",
      website: "www.cnil.fr",
      websiteLink: "http://www.cnil.fr/",
      title: `Commission Nationale de l'Informatique et des Libertés - CNIL`,
    },
    contact: {
      tel: ["+33 1 53 73 22 22"],
      fax: ["+33 1 53 73 22 00"],
      email: [],
    },
  },
  {
    name: { title: "Germany", code: "de" },
    institution: {
      location: "",
      website: "www.bfdi.bund.de",
      websiteLink: "http://www.bfdi.bund.de/",
      title:
        "Die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit",
    },
    contact: {
      tel: ["+49 228 997799 0", "+49 228 81995 0"],
      fax: ["+49 228 997799 550", "+49 228 81995 550"],
      email: ["poststelle@bfdi.bund.de"],
    },
  },
  {
    name: { title: "Greece", code: "gr" },
    institution: {
      location: "",
      website: "www.dpa.gr",
      websiteLink: "http://www.dpa.gr/",
      title: "Hellenic Data Protection Authority",
    },
    contact: {
      tel: ["+30 210 6475 600"],
      fax: ["+30 210 6475 628"],
      email: ["contact@dpa.gr"],
    },
  },
  {
    name: { title: "Hungary", code: "hu" },
    institution: {
      location: "",
      website: "www.naih.hu",
      websiteLink: "http://www.naih.hu/",
      title:
        "National Authority for Data Protection and Freedom of Information",
    },
    contact: {
      tel: ["+36 1 3911 400"],
      fax: [],
      email: ["peterfalvi.attila@naih.hu"],
    },
  },
  {
    name: { title: "Ireland", code: "ie" },
    institution: {
      location: "",
      website: "www.dataprotection.ie",
      websiteLink: "http://www.dataprotection.ie/",
      title: "Data Protection Commissioner ",
    },
    contact: {
      tel: ["+353 57 868 4800"],
      fax: ["+353 57 868 4757"],
      email: ["info@dataprotection.ie"],
    },
  },
  {
    name: { title: "Italy", code: "it" },
    institution: {
      location: "",
      website: "www.garanteprivacy.it",
      websiteLink: "http://www.garanteprivacy.it/",
      title: "Garante per la protezione dei dati personali",
    },
    contact: {
      tel: ["+39 06 69677 1"],
      fax: ["+39 06 69677 785"],
      email: ["garante@garanteprivacy.it"],
    },
  },
  {
    name: { title: "Latvia", code: "lv" },
    institution: {
      location: "",
      website: "www.dvi.gov.lv",
      websiteLink: "http://www.dvi.gov.lv/",
      title: "Data State Inspectorate",
    },
    contact: {
      tel: ["+371 6722 3131"],
      fax: ["+371 6722 3556"],
      email: ["info@dvi.gov.lv"],
    },
  },
  {
    name: { title: "Lithuania", code: "lt" },
    institution: {
      location: "",
      website: "www.ada.lt",
      websiteLink: "http://www.ada.lt/",
      title: "State Data Protection",
    },
    contact: {
      tel: ["+370 5 279 14 45"],
      fax: ["+370 5 261 94 94"],
      email: ["ada@ada.lt"],
    },
  },
  {
    name: { title: "Luxembourg", code: "lu" },
    institution: {
      location: "",
      website: "www.cnpd.lu",
      websiteLink: "http://www.cnpd.lu/",
      title: "Commission Nationale pour la Protection des Données",
    },
    contact: {
      tel: ["+352 2610 60 1"],
      fax: ["+352 2610 60 29"],
      email: ["info@cnpd.lu"],
    },
  },
  {
    name: { title: "Malta", code: "mt" },
    institution: {
      location: "",
      website: "www.dataprotection.gov.mt",
      websiteLink: "http://www.dataprotection.gov.mt/",
      title: "Office of the Data Protection Commissioner ",
    },
    contact: {
      tel: ["+356 2328 7100"],
      fax: ["+356 2328 7198"],
      email: ["commissioner.dataprotection@gov.mt"],
    },
  },
  {
    name: { title: "Netherlands", code: "nl" },
    institution: {
      location: "",
      website: "autoriteitpersoonsgegevens.nl/nl",
      websiteLink: "https://autoriteitpersoonsgegevens.nl/nl",
      title: "Autoriteit Persoonsgegevens ",
    },
    contact: {
      tel: ["+31 70 888 8500"],
      fax: ["+31 70 888 8501"],
      email: ["info@autoriteitpersoonsgegevens.nl"],
    },
  },
  {
    name: { title: "Poland", code: "pl" },
    institution: {
      location: "",
      website: "www.giodo.gov.pl",
      websiteLink: "http://www.giodo.gov.pl/",
      title:
        "The Bureau of the Inspector General for the Protection of Personal Data - GIODO",
    },
    contact: {
      tel: ["+48 22 53 10 440"],
      fax: ["+48 22 53 10 441"],
      email: ["kancelaria@giodo.gov.pl", "desiwm@giodo.gov.pl"],
    },
  },
  {
    name: { title: "Portugal", code: "pt" },
    institution: {
      location: "",
      website: "www.cnpd.pt",
      websiteLink: "http://www.cnpd.pt/",
      title: "Comissão Nacional de Protecção de Dados - CNPD",
    },
    contact: {
      tel: ["+351 21 392 84 00"],
      fax: ["+351 21 397 68 32"],
      email: ["geral@cnpd.pt"],
    },
  },
  {
    name: { title: "Romania", code: "ro" },
    institution: {
      location: "",
      website: "www.dataprotection.ro",
      websiteLink: "http://www.dataprotection.ro/",
      title: "The National Supervisory Authority for Personal Data Processing",
    },
    contact: {
      tel: ["+40 21 252 5599"],
      fax: ["+40 21 252 5757"],
      email: ["anspdcp@dataprotection.ro"],
    },
  },
  {
    name: { title: "Slovakia", code: "sk" },
    institution: {
      location: "",
      website: "www.dataprotection.gov.sk",
      websiteLink: "http://www.dataprotection.gov.sk/",
      title: "Office for Personal Data Protection of the Slovak Republic",
    },
    contact: {
      tel: ["+ 421 2 32 31 32 14"],
      fax: ["+ 421 2 32 31 32 34"],
      email: ["statny.dozor@pdp.gov.sk"],
    },
  },
  {
    name: { title: "Slovenia", code: "si" },
    institution: {
      location: "",
      website: "www.ip-rs.si",
      websiteLink: "https://www.ip-rs.si/",
      title: "Information Commissioner ",
    },
    contact: {
      tel: ["+386 1 230 9730"],
      fax: ["+386 1 230 9778"],
      email: ["gp.ip@ip-rs.si"],
    },
  },
  {
    name: { title: "Spain", code: "es" },
    institution: {
      location: "",
      website: "www.agpd.es",
      websiteLink: "https://www.agpd.es/",
      title: "Agencia de Protección de Datos",
    },
    contact: {
      tel: ["+34 91399 6200"],
      fax: ["+34 91455 5699"],
      email: ["internacional@agpd.es"],
    },
  },
  {
    name: { title: "United Kingdom", code: "gb" },
    institution: {
      location: "",
      website: "ico.org.uk",
      websiteLink: "https://ico.org.uk/",
      title: "The Information Commissioner’s Office",
    },
    contact: {
      tel: ["+44 1625 545 745"],
      fax: [],
      email: ["international.team@ico.org.uk"],
    },
  },
  {
    name: { title: "Iceland", code: "is" },
    institution: {
      location: "",
      website: "personuvernd.is",
      websiteLink: "http://personuvernd.is/",
      title: "Icelandic Data Protection Agency",
    },
    contact: {
      tel: ["+354 510 9600"],
      fax: ["+354 510 9606"],
      email: ["postur@personuvernd.is"],
    },
  },
  {
    name: { title: "Liechtenstein", code: "li" },
    institution: {
      location: "",
      website: "www.dss.llv.li/",
      websiteLink: "http://www.dss.llv.li/",
      title: "Data Protection Office",
    },
    contact: {
      tel: ["+423 236 6090"],
      fax: [],
      email: ["info.dss@llv.li"],
    },
  },
  {
    name: { title: "Norway", code: "no" },
    institution: {
      location: "",
      website: "www.datatilsynet.no",
      websiteLink: "http://www.datatilsynet.no/",
      title: "The Data Inspectorate",
    },
    contact: {
      tel: ["+47 22 39 69 00;"],
      fax: ["+47 22 42 23 50"],
      email: ["postkasse@datatilsynet.no"],
    },
  },
  {
    name: { title: "Switzerland", code: "ch" },
    institution: {
      location: "",
      website: "www.edoeb.admin.ch",
      websiteLink: "http://www.edoeb.admin.ch/",
      title: "Information Commissioner ",
    },
    contact: {
      tel: ["+41 31 322 4395"],
      fax: ["+41 31 325 9996"],
      email: ["contact20@edoeb.admin.ch"],
    },
  },
];
