import { Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { hexToRgba } from "utils/helpers";

interface DetailsProps {
  tintColor: string;
  bio: string;
  academic: string;
}

const MotionFlex = motion(Flex);
const MotionText = motion(Text);

function Details({ tintColor, bio, academic }: DetailsProps) {
  const animationVariants = {
    container: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0, transition: { delay: 0.3 } },
    },
    content: {
      initial: { y: -20, opacity: 0 },
      animate: { y: 0, opacity: 1, transition: { delay: 0.3, bounce: 0 } },
      exit: { y: -20, opacity: 0, transition: { bounce: 0 } },
    },
  };
  return (
    <MotionFlex
      position={"absolute"}
      inset={0}
      direction={"column"}
      p={4}
      bg={hexToRgba(tintColor, 0.9)}
      backdropFilter={"blur(6px)"}
      initial={"initial"}
      animate={"animate"}
      exit={"exit"}
      variants={animationVariants["container"]}
    >
      <Flex
        direction={"column"}
        justify={"space-between"}
        h={"calc(100% - 55px)"}
      >
        {/* bio */}
        <Flex direction={"column"} gap={2}>
          {bio?.split("\n")?.map((line, index) => (
            <MotionText
              key={index}
              variants={animationVariants["content"]}
              color={"#ebebf3"}
              fontSize={"15px"}
            >
              - {line}
            </MotionText>
          ))}
        </Flex>

        {/* academic */}
        <Flex direction={"column"} gap={1.5}>
          {academic?.split("\n")?.map((line, index) => (
            <MotionText
              key={index}
              fontStyle={"italic"}
              color={"#fff"}
              fontSize={"13px"}
              variants={animationVariants["content"]}
            >
              {line}
            </MotionText>
          ))}
        </Flex>
      </Flex>
    </MotionFlex>
  );
}

export default Details;
