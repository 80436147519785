import { useEffect } from "react";
import { animate, motion, useMotionValue } from "framer-motion";
import useMeasure from "react-use-measure";

import { Box, Flex, Image, Text } from "@chakra-ui/react";

import askLePoisLogo from "assets/home/companies/asklepois.svg";
import awsLogo from "assets/home/companies/aws.svg";
import googleLogo from "assets/home/companies/google.svg";
import nvidiaLogo from "assets/home/companies/nvidia.svg";
import uclLogo from "assets/home/companies/ucl.svg";
import sidraLogo from "assets/home/companies/sidra.svg";
import kemriLogo from "assets/home/companies/kemri.svg";
import pathLogo from "assets/home/companies/path.svg";
import csirLogo from "assets/home/companies/csir.svg";
import jluLogo from "assets/home/companies/jlu.svg";
import aachenLogo from "assets/home/companies/aachen.svg";
import daystarLogo from "assets/home/companies/daystar.png";
import { useTranslation } from "react-i18next";

export interface CompanyProps {
  src: string;
  alt: string;
}

export const companiesList: CompanyProps[] = [
  { src: awsLogo, alt: "AWS" },
  { src: googleLogo, alt: "Google" },
  { src: nvidiaLogo, alt: "Nvidia" },
  { src: uclLogo, alt: "UCL" },
  { src: sidraLogo, alt: "Sidra" },
  { src: kemriLogo, alt: "Kemri" },
  { src: askLePoisLogo, alt: "Asklepois" },
  { src: aachenLogo, alt: "Aachen University" },
  { src: daystarLogo, alt: "Daystar University" },
  { src: jluLogo, alt: "University of Giessen" },
  { src: csirLogo, alt: "CSIR" },
  { src: pathLogo, alt: "PATH Org." },
];

const FAST_DURATION = 25;

export default function TrustedBy() {
  // Hooks
  const { t, i18n } = useTranslation();
  let [ref, { width }] = useMeasure();
  const xTranslation = useMotionValue(0);

  useEffect(() => {
    let controls;
    const finalPosition = -width / 2 - 5;

    controls = animate(xTranslation, [0, finalPosition], {
      ease: "linear",
      duration: FAST_DURATION,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0,
      bounce: 0,
    });

    return controls?.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xTranslation, width]);

  return (
    <Flex
      gap={{ lg: 4, xl: 6 }}
      maxW={"100%"}
      mx={"auto"}
      bg={"#323383"}
      align={"center"}
    >
      <Text
        pl={4}
        color={"#d6d6e6"}
        fontWeight={"500"}
        w={i18n?.language === "en" ? "120px" : "210px"}
        fontSize={{ lg: "16px", xl: "18px" }}
      >
        {t("header.trusted")}
      </Text>
      <Flex
        w={"100%"}
        mx={{ base: "auto", lg: 0 }}
        overflow={"hidden"}
        bg={"#323383"}
        position="relative"
        _before={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          width: "60px",
          bgGradient: "linear(to-r, #323383, transparent)",
          zIndex: 5,
        }}
      >
        <Box
          as={motion.div}
          style={{
            x: xTranslation,
            display: "flex",
            gap: 0,
            padding: 0,
            margin: 0,
          }}
          ref={ref}
        >
          {[...companiesList, ...companiesList].map((cmp, idx) => (
            <Company src={cmp?.src} alt={cmp?.alt} key={idx} />
          ))}
        </Box>
      </Flex>
    </Flex>
  );
}

function Company({ src, alt }: CompanyProps) {
  return (
    <Flex
      position={"relative"}
      overflow={"hidden"}
      h={"130px"}
      minW={"130px"}
      justify={"center"}
      align={"center"}
      px={"20px"}
    >
      <Image src={src} alt={alt} objectFit={"cover"} />
    </Flex>
  );
}
