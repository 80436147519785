import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import { Flex, Box, ChakraProvider, useMediaQuery } from "@chakra-ui/react";

// Shared imports
import Nav from "shared/nav";
import AuthLayout from "components/auth/AuthLayout";

// Views imports
import Home from "views/home";
import Investor from "views/investor";
import Login from "views/login";
import Register from "views/register";
import Contacts from "views/contacts";
import PageNotFound from "views/PageNotFound";

// Theme imports
import generateTheme from "theme";
import MobileView from "views/MobileView";

import { ROLES } from "utils/premissions";
import Unauthorized from "views/Unauthorized";
import RequireAuth from "components/auth/RequireAuth";
import PersistLogin from "components/auth/PersistLogin";
import { useEffect } from "react";
import { useLocationAPI } from "api/useLocationAPI";

function Layout() {
  const borderColor = "transparent";

  return (
    <Flex
      direction="column"
      position={"relative"}
      bg={"background"}
      p={0}
      minHeight={"100vh"}
    >
      {/* navbar */}
      <Box
        as="header"
        position="fixed"
        width="100%"
        zIndex="10"
        padding={["32px 18px 0", "32px 72px 0"]}
        bg="transparent"
      >
        <Nav />
      </Box>

      {/* main panel */}
      <Box
        as="main"
        w={"100%"}
        borderColor={borderColor}
        bg={"background"}
        flex="1"
        p="0"
        mt="0"
      >
        <Outlet />
      </Box>
    </Flex>
  );
}

function AppWrapper() {
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  if (isMobileView) return <MobileView isMobileView={isMobileView} />;

  return (
    <Box visibility={!isMobileView ? "visible" : "hidden"}>
      <Outlet />
    </Box>
  );
}

export default function AppRoutes() {
  // Theme
  const theme = generateTheme();

  // APIs
  const { getLocation } = useLocationAPI();

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const res = await getLocation();
        const countryCode = res?.countryCode;
        // only check if user's current location is germany
        if (countryCode && countryCode === "DE") {
          localStorage.setItem(
            "preferredLang",
            JSON.stringify(countryCode.toLocaleLowerCase())
          );
        }
      } catch (error) {
        console.log("useLocationAPI Error", error);
      }
    };

    getUserLocation();
  }, [getLocation]);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route element={<AppWrapper />}>
            {/* Public routes */}
            <Route element={<PersistLogin />}>
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
              </Route>

              <Route element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="contacts" element={<Contacts />} />

                {/* Protected routes */}
                <Route
                  element={
                    <RequireAuth allowedTypes={[...Object.values(ROLES)]} />
                  }
                >
                  <Route path="investor-relations" element={<Investor />} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
