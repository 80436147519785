import axios from "axios";

export const useLocationAPI = () => {
  const getLocation = async () => {
    const response = await axios.get("https://api.db-ip.com/v2/free/self");
    return response.data;
  };

  return { getLocation };
};
