import { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";

import BackToTop from "components/ui/BackToTop";
import Header from "components/contacts/Header";
import ContactsTable from "components/contacts/ContactsTable";

export default function ContactsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction={"column"} bg={"transparent"} mb={"64px"}>
      <BackToTop />

      <Box
        w="85%"
        mx={"auto"}
        maxW="1200px"
        margin="auto"
        pt="112px"
        px={4}
        // pb="112px"
      >
        <Header />

        <ContactsTable />
      </Box>
    </Flex>
  );
}
