import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const _preferredLang = localStorage.getItem("preferredLang");
const preferredLang = _preferredLang ? JSON.parse(_preferredLang) : "en";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: preferredLang,
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          language: "EN",
          nav: {
            item1: "Home",
            item2: "Investor Relations",
            item3: "Logout",
            item4: "Logging out",
          },
          header: {
            seq1: "EmpathicAI.life",
            seq2: "AI for Life",
            seq3: "AI for Health",
            seq4: "AI for Prosperity",
            seq5: "AI at the Service of Humanity",
            cta: "Get involved",
            trusted: "Trusted by",
          },
          mission: {
            subHeading: "OUR MISSION",
            heading: "Research and Create Empathic AI",
            card1: {
              heading: "HUMAN-AI SYMBIOSIS",
              text: "AI is amplifying humans to scale the reach and cognitive capabilities of human experts",
            },
            card2: {
              heading: "EMPATHETIC USE CASES",
              text: "Focus on helping humans worldwide to live healthier, longer, and more prosperously",
            },
            card3: {
              heading: "ETHICAL & REPRESENTATIVE",
              text: "Ethical representation of human diversity, minimize bias and lead to strong and empathic AI-solutions",
            },
          },
          brands: {
            heading: "AI Ideas that we are Building",
            cta: "Explore Website",
            soon: "Launching soon..",
            brand1: {
              heading:
                "Venture builder pioneering ethical and representative AI ventures",
            },
            brand2: { heading: "Connecting the dots for life sciences" },
            brand3: { heading: "Your AI Agent for scientific research" },
            brand4: { heading: "Connecting Life's Molecules" },
            brand5: {
              heading: "Supporting your neurohealth journey, every step!",
              text: "We're revolutionizing caregiving for families with autism and special needs, fostering a supportive environment and offering essential resources.\nThrough therapy and community support, we're not just caregivers; we're life-changers.",
            },
            brand6: { heading: "AI life coach for the neurodivergent" },
            brand7: {
              heading:
                "Gamified Digital Biomarkers to Screen for Neuropsychiatric Conditions",
            },
          },
          team: {
            subHeading: "WORLD CLASS LEADERSHIP TEAM",
            heading:
              "Strong Leadership team with Expertise in AI, Biomedical Innovation, Health Stakeholders and Global Capacity Building",
            member1: {
              position: "Co-Founder and CEO",
              bio: "AI expert and strategist experienced entrepreneur of innovative and empathetic healthcare ventures.\nEx Advisor on AI to various leading Life Science companies such as Novartis Merck and Roche.\nFormer McKinsey consultant",
              academic: "PhD Artificial Intelligence Hamburg, Postdoc Harvard",
            },
            member2: {
              position: "Senior Advisor and Board Member",
              bio: "A senior strategic advisor with a strong background in academic and international governmental institutions, particularly in the MEA region.\nShe has a reputation for excellence and integrity and is skilled in advising on culture, society, and politics in the region.",
              academic: "2 Degrees from Georgetown University",
            },
            member3: {
              position: "Senior Advisor and Board Member",
              bio: "Operations and market expert specialized in impact-driven change, digital transformation, and innovation.\nExtensive senior leadership experience in leading pharma / biotechnology, automotive, and financial services.\nPrior experience at Roche / Genentech, Zurich Life, BMW and UBS.",
              academic: "Degrees from Zurich",
            },
            member4: {
              position: "Senior Advisor on Biochemical Engineering",
              bio: "Biochemical Engineer, inventor, and entrepreneur with expertise in biologics development.\nFormer CTO of a leading Biotechnology innovation company.\nGlobal Biotech leader on  sustainable & cost-effective product development, and process optimization for biologics, vaccines, and gene therapies.",
              academic: "PhD in Biochemical Engineering UCL London",
            },
            member5: {
              position: "Venture Partner",
              bio: "Experienced in finance, investing and value creation in private equity.\nThought Leader in the German Health-Tech Industry and current Venture Partner at Heal Capital.",
              academic: "PhD in Philosophy",
            },
            member6: {
              position: "Co-Founder and Head of Scientific Operations",
              bio: "Experience medical science innovator. She has led skilled research teams at the forefront of Telemedicine, AI-based screening and AI-supported methods for drug development.\nEx Medical and Scientific operations advisor to Merck, Amgen, Roche, Bayer etc in the fields of neurology, ophthalmology and oncology.",
              academic: "PhD Medicinal Chemistry Hamburg",
            },
          },
          auth: {
            copyright: "All Rights Reserved",
            privacy: "Privacy Policy",
            login: {
              heading: "Sign in to your account",
              subHeading: "Welcome back",
              text: "Don't have an account?",
              link: "Sign Up",
              form: {
                email: "Email Address",
                emailPlaceholder: "Email Address",
                password: "Password",
                passwordPlaceholder: "Password",
                btnText: "Login",
                loadingText: "Authenticating...",
              },
            },
            register: {
              heading: "Register",
              subHeading: "Welcome",
              text: "Already have an account?",
              link: "Login",
              form: {
                email: "Email Address",
                emailPlaceholder: "Email Address",
                password: "Password",
                passwordPlaceholder: "Enter your password",
                code: "Access Code",
                codePlaceholder: "Referall Code",
                btnText: "Sign Up",
                loadingText: "Processing...",
                verificationCode: "Verification Code",
                verificationCodePlaceholder: "Enter the verification code",
                verificationCodeHelper:
                  "The verification code has been sent to the email address provided.",
                btnText2: "Verify",
                loadingText2: "Verifying",
                loadingText3: "Signing In",
              },
            },
          },
          policy: {
            section1: {
              heading: "Introduction",
              part1:
                "Thank you for choosing to be part of our community at EmpathicAI.life. We are committed to protecting your personal information and right to privacy governed by the principles of General Data Protection Regulation (GDPR). If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at ",
              part2:
                "When you visit our website, use our mobile application or any of our services, we appreciate that you are trusting us with your personal information. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. Please take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please inform us immediately.",
            },
            section2: {
              heading: "What data do we collect?",
              part1:
                "EmpathicAI.life collects personal data for the purpose of improving your user experience.",
              part1_1:
                "Personal identification information (Name, email address, phone number etc.)",
              part1_2:
                "EmpathicAI.life may collect personal data when you use some of our services. In this case we are guided by regulations ensuring duly informed consent is explicitly received before we collect such data.",
              part2:
                "If you leave a message on the Website, your name and email address will also be saved in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will be saved on your computer until you delete them.",
              part2_1:
                "IP and browser user agent string: this data is collected when you leave a comment.",
              part2_2:
                "Retention period: The aforementioned data is retained indefinitely so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.",
            },
            section3: {
              heading: "How do we collect your data?",
              part1:
                "You directly provide EmpathicAI.life with most of the data we collect. We collect data and process data when you:",
              part1_1: "Sign up to EmpathicAI.life",
              part1_2: "Fill out our contact form",
              part1_3:
                "EmpathicAI.life may also receive your data indirectly from your browser as inputs to cookies.",
            },
            section4: {
              heading: "How do we use your data?",
              part1:
                "EmpathicAI.life collects your data for the following purposes:",
              part1_1:
                "Improve user experience and curate relevant information for users.",
              part1_2:
                "The data will anonymously be used to improve the website.",
              part1_3: "This may include pseudonymized testimonials.",
              part1_4: "Evaluate investment participation opportunities.",
              part1_5: "For marketing purposes.",
            },
            section5: {
              heading: "How do we store your data?",
              part1:
                "EmpathicAI.life securely stores your data at our cloud data storage facilities hosted in the cloud in region Frankfurt. We strictly adhere to the GDPR principle of storage limitation and data retention policies; therefore, we erase or anonymize any personal data that is no longer needed. A client also has the right to revoke access and request us to delete all their personal data at any given time.",
            },
            section6: {
              heading: "Marketing",
              part1:
                "EmpathicAI.life would like to send you information about products and services of ours that we think you might like, as well as those of our partners.",
              part2:
                "If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop EmpathicAI.life from contacting you for marketing purposes or giving your data to other members of the EmpathicAI.life Company. If you no longer wish to be contacted for marketing purposes, you will be able to make the changes in your profile on our website",
            },
            section7: {
              heading: "What are your data protection rights?",
              part1:
                "Our team would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:",
              part1_1:
                "The right to access- You have the right to request EmpathicAI.life for copies of your personal data.",
              part1_2:
                "The right to rectification - You have the right to request that EmpathicAI.life correct any information you believe is inaccurate. You also have the right to request EmpathicAI.life to complete the information you believe is incomplete.",
              part1_3:
                "The right to erasure - You have the right to request that EmpathicAI.life erase your personal data, under certain conditions.",
              part1_4:
                "The right to restrict processing - You have the right to request that EmpathicAI.life restrict the processing of your personal data, under certain conditions.",
              part1_5:
                "The right to object to processing - You have the right to object to EmpathicAI.life's processing of your personal data, under certain conditions.",
              part1_6:
                "The right to data portability - You have the right to request that EmpathicAI.life transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
              part2:
                "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us: ",
            },
            section8: {
              heading: "Cookies",
              part1:
                "Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology. For further information, visit ",
              part2:
                "EmpathicAI.life uses cookies in a range of ways to improve your experience on our website, including:",
              part2_1: "Keeping you signed in",
              part2_2: "Understanding how you use our website",
            },
            section9: {
              heading: "What types of cookies do we use?",
              part1:
                "There are a number of different types of cookies, however, our website uses:",
              part1_1:
                "Functionality - EmpathicAI.life uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.",
              part1_2:
                "Advertising - EmpathicAI.life uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. EmpathicAI.life sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.",
            },
            section10: {
              heading: "How to manage cookies?",
              part1:
                "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.",
            },
            section11: {
              heading: "Privacy policies of other websites",
              part1:
                "Our site may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.",
            },
            section12: {
              heading: "Changes to our privacy policy",
              part1:
                "EmpathicAI.life keeps its privacy policy under regular review, any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy. This privacy policy was last updated on 19 November 2024.",
            },
            section13: {
              heading: "How to contact us?",
              part1:
                "If you have any questions about EmpathicAI.life's privacy policy, your data with us, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.",
              part2: "Call us: ",
              part3: "Or write to us at: ",
            },
            section14: {
              heading: "How to contact the appropriate authority?",
              part1:
                "Should you wish to report a complaint or if you feel that EmpathicAI.life has not addressed your concern in a satisfactory manner, you may contact the Data Commissioner's Office.",
            },
          },
        },
      },
      de: {
        translation: {
          language: "DE",
          nav: {
            item1: "Startseite",
            item2: "Investor Relations",
            item3: "Abmeldung",
            item4: "Abmelden",
          },
          header: {
            seq1: "EmpathicAI.life AG",
            seq2: "KI für das Leben",
            seq3: "KI für Gesundheit",
            seq4: "KI für Wohlstand",
            seq5: "KI im Dienst der Menschheit",
            cta: "Beteilige dich",
            trusted: "In Partnerschaft mit",
          },
          mission: {
            subHeading: "Unsere Mission",
            heading: "Empathische KI erforschen und entwickeln",
            card1: {
              heading: "Symbiose KI und Mensch",
              text: "KI hilft menschlicher Experten, die Reichweite und kognitiven Fähigkeiten  zu skalieren",
            },
            card2: {
              heading: "Anwendungsfälle",
              text: "Konzentration Menschen zu helfen, gesünder und wohlhabender zu leben",
            },
            card3: {
              heading: "Ethisch & Repräsentativ",
              text: "Ethische Repräsentation menschlicher Vielfalt, Minimierung von Vorurteilen und Entwicklung starker und empathischer KI-Lösungen",
            },
          },
          brands: {
            heading: "KI Ideen, die wir entwickeln",
            cta: "Webseite erkunden",
            soon: "Start in Kürze..",
            brand1: {
              heading:
                "Venture Builder, der Pionierarbeit für ethische und repräsentative KI-Projekte leistet",
            },
            brand2: {
              heading: "Verbindung der Punkte für die Biowissenschaften",
            },
            brand3: { heading: "Ihr KI-Agent für wissenschaftliche Forschung" },
            brand4: { heading: "Die Moleküle des Lebens verbinden" },
            brand5: {
              heading:
                "Wir unterstützen Sie bei jedem Schritt auf Ihrem Weg zur Neurogesundheit!",
              text: "Wir revolutionieren die Betreuung von Familien mit Autismus und besonderen Bedürfnissen, fördern ein unterstützendes Umfeld und bieten wichtige Ressourcen.\nDurch Therapie und Unterstützung der Gemeinschaft sind wir nicht nur Betreuer, sondern verändern Leben.",
            },
            brand6: { heading: "KI-Lebensberater für neurodiverse Menschen" },
            brand7: {
              heading:
                "Digitale Biomarker mit Gamification-Elementen zur Früherkennung neuropsychiatrischer Erkrankungen",
            },
          },
          team: {
            subHeading: "Weltklasse Führungsteam",
            heading:
              "Starkes Führungsteam mit Expertise in KI, biomedizinischer Innovation, Gesundheitsakteuren und globalem Kapazitätsaufbau",
            member1: {
              position: "Co-Founder und CEO, Verwaltungsratspräsident",
              bio: "KI-Experte, Stratege und erfahrener Unternehmer innovativer Gesundheitsprojekte.\nEhemaliger KI-Berater für führende Life-Science-Unternehmen wie Novartis, Merck und Roche.\nEhemaliger McKinsey Berater",
              academic:
                "Doktortitel in KI von der TU Hamburg, Postdoc an der Harvard University",
            },
            member2: {
              position: "Verwaltungsrätin",
              bio: "Senior Advisor mit fundiertem Hintergrund in akademischen und internationalen Regierungsinstitutionen, insbesondere in der MEA-Region.\nSie geniesst einen ausgezeichneten Ruf für ihre Integrität und ist spezialisiert auf Beratung zu Gesellschaft, Kultur und Politik in der Region.",
              academic: "2 Abschlüsse an der Georgetown University",
            },
            member3: {
              position: "Senior Advisor & Verwaltungsrat",
              bio: "Unternehmens- und Marktexperte, spezialisiert auf digitale Transformation und Einführen von Innovationen.\nUmfangreiche Führungserfahrung in der Pharma/Biotechnologie, Automobilindustrie und Finanzindustrie.\nErfahrung bei Roche / Genentech, Zurich Insurance, BMW und UBS",
              academic: "Abschlüsse von Zürich HWZ",
            },
            member4: {
              position: "Senior Advisor für Biochemie-Ingenieurwesen",
              bio: "Ingenieurin der Biochemie, Entwicklerin und Unternehmerin mit Expertise in der Entwicklung von Biologics.\nEhemalige Technologiechefin eines führenden Unternehmens für biotechnologische Innovationen.\nGlobale führende Biotechspezialistin für nachhaltige und kosteneffektive Produktentwicklung sowie Prozessoptimierung für Biologika, Impfstoffe und Gentherapien.",
              academic: "Doktortitel in Biochemischem Engineering, UCL London",
            },
            member5: {
              position: "Venture Partner",
              bio: "Erfahrung in den Bereichen Finanzen, Investitionen und Wertschöpfung im Bereich Private Equity.\nVordenker in der deutschen Health-Tech-Branche und derzeit Venture Partner bei Heal Capital.",
              academic: "PhD in Philosophie",
            },
            member6: {
              position: "Co-Founder und Leiterin Wissenschaftsteam",
              bio: "Erfahrene Führungskraft in der medizinischen Wissenschaft. Sie hat hochqualifizierte Forschungsteams in den Bereichen Telemedizin, KI-basierte Diagnostik und KI-unterstützte Methoden zur Arzneimittelentwicklung geleitet.\nEhemalige Beraterin für medizinische und wissenschaftliche Operationen bei Merck, Amgen, Roche, Bayer etc. in den Bereichen Neurologie, Ophthalmologie und Onkologie",
              academic: "Doktortitel in Medizinischer Chemie, Hamburg",
            },
          },
          auth: {
            copyright: "Alle Rechte vorbehalten",
            privacy: "Datenschutzrichtlinie",
            login: {
              heading: "Melden Sie sich bei Ihrem Konto an",
              subHeading: "Willkommen zurück",
              text: "Noch kein Konto?",
              link: "Hier registrieren",
              form: {
                email: "E-Mail Adresse",
                emailPlaceholder: "E-Mail Adresse",
                password: "Passwort",
                passwordPlaceholder: "Passwort",
                btnText: "Anmeldung",
                loadingText: "Authentifizierung läuft...",
              },
            },
            register: {
              heading: "Registrierung",
              subHeading: "Willkommen",
              text: "Bereits ein Konto?",
              link: "Anmelden",
              form: {
                email: "E-Mail Adresse",
                emailPlaceholder: "E-Mail Adresse",
                password: "Passwort",
                passwordPlaceholder: "Geben Sie Ihr Passwort ein",
                code: "Zugangscode",
                codePlaceholder: "Empfehlungscode",
                btnText: "Hier registrieren",
                loadingText: "Verarbeitung läuft...",
                verificationCode: "Verifizierungscode",
                verificationCodePlaceholder:
                  "Geben Sie Ihr Verifizierungscode ein",
                verificationCodeHelper:
                  "Der Verifizierungscode wurde an die angegebene E-Mail Adresse gesendet.",
                btnText2: "Verifizierung",
                loadingText2: "Verifizierung läuft",
                loadingText3: "Verifizierung läuft",
              },
            },
          },
          policy: {
            section1: {
              heading: "Einleitung",
              part1:
                "Vielen Dank, dass Sie sich entschieden haben, Teil unserer Community bei EmpathicAI.life zu werden. Wir verpflichten uns, Ihre persönlichen Daten und Ihr Recht auf Privatsphäre gemäß den Grundsätzen der Datenschutzverordnung (DSGVO) zu schützen. Wenn Sie Fragen oder Bedenken zu diesem Datenschutzhinweis oder zu unseren Richtlinien in Bezug auf Ihre persönlichen Daten haben, kontaktieren Sie uns bitte unter ",
              part2:
                "Wenn Sie unsere Website besuchen, unsere mobile Anwendung oder einen unserer Dienste nutzen, sind wir uns bewusst, dass Sie uns Ihre persönlichen Daten anvertrauen. In dieser Datenschutzerklärung möchten wir Ihnen so klar wie möglich erklären, welche Daten wir sammeln, wie wir sie verwenden und welche Rechte Sie in Bezug auf diese Daten haben. Bitte nehmen Sie sich Zeit, um sie sorgfältig durchzulesen, denn sie ist wichtig. Sollten Sie mit einigen Bestimmungen in dieser Datenschutzerklärung nicht einverstanden sein, informieren Sie uns bitte umgehend.",
            },
            section2: {
              heading: "Welche Daten sammeln wir?",
              part1:
                "EmpathicAI.life sammelt personenbezogene Daten, um Ihre Nutzererfahrung zu verbessern.",
              part1_1:
                "Persönliche Identifikationsdaten (Name, E-Mail-Adresse, Telefonnummer usw.)",
              part1_2:
                "EmpathicAI.life kann persönliche Daten sammeln, wenn Sie einige unserer Dienste nutzen. In diesem Fall werden wir von Vorschriften geleitet, die sicherstellen, dass eine ordnungsgemäß informierte Zustimmung ausdrücklich erhalten wird, bevor wir solche Daten sammeln.",
              part2:
                "Wenn Sie eine Nachricht auf der Website hinterlassen, werden auch Ihr Name und Ihre E-Mail-Adresse in Cookies gespeichert. Diese dienen Ihrer Bequemlichkeit, damit Sie Ihre Daten nicht erneut eingeben müssen, wenn Sie einen weiteren Kommentar hinterlassen. Diese Cookies werden auf Ihrem Computer gespeichert, bis Sie sie löschen.",
              part2_1:
                "IP und Browser-Benutzer-Agent-String: Diese Daten werden erfasst, wenn Sie einen Kommentar hinterlassen.",
              part2_2:
                "Aufbewahrungsdauer: Die vorgenannten Daten werden auf unbestimmte Zeit gespeichert, damit wir eventuelle Folgekommentare automatisch erkennen und genehmigen können, anstatt sie in einer Moderationswarteschlange zu halten.",
            },
            section3: {
              heading: "Wie sammeln wir Ihre Daten?",
              part1:
                "Die meisten der von uns erhobenen Daten stellen Sie EmpathicAI.life direkt zur Verfügung. Wir sammeln Daten und verarbeiten Daten, wenn Sie:",
              part1_1: "Registrieren Sie sich bei EmpathicAI.life",
              part1_2: "Füllen Sie unser Kontaktformular aus",
              part1_3:
                "EmpathicAI.life kann Ihre Daten auch indirekt von Ihrem Browser als Eingaben in Cookies erhalten.",
            },
            section4: {
              heading: "Wie werden wir Ihre Daten verwenden?",
              part1:
                "EmpathicAI.life sammelt Ihre Daten für die folgenden Zwecke:",
              part1_1:
                "Verbessern Sie die Nutzererfahrung und stellen Sie relevante Informationen für die Nutzer zusammen.",
              part1_2:
                "Die Daten werden anonymisiert zur Verbesserung der Website verwendet.",
              part1_3: "Dazu können auch pseudonymisierte Zeugnisse gehören.",
              part1_4: "Bewertung von Investitionsbeteiligungsmöglichkeiten.",
              part1_5: "Für Marketingzwecke.",
            },
            section5: {
              heading: "Wie speichern wir Ihre Daten?",
              part1:
                "EmpathicAI.life speichert Ihre Daten sicher in unserem Cloud-Datenspeicher, der in der Cloud in der Region Frankfurt gehostet wird. Wir halten uns strikt an den GDPR-Grundsatz der Speicherbegrenzung und Datenaufbewahrungsrichtlinien; daher löschen oder anonymisieren wir alle personenbezogenen Daten, die nicht mehr benötigt werden. Ein Kunde hat außerdem das Recht, den Zugang zu seinen Daten zu widerrufen und uns aufzufordern, alle seine personenbezogenen Daten zu löschen.",
            },
            section6: {
              heading: "Marketing",
              part1:
                "EmpathicAI.life möchte Ihnen Informationen über Produkte und Dienstleistungen von uns, die Ihnen gefallen könnten, sowie über die unserer Partner schicken.",
              part2:
                "Wenn Sie zugestimmt haben, Marketing zu erhalten, können Sie sich jederzeit zu einem späteren Zeitpunkt abmelden. Sie haben jederzeit das Recht, EmpathicAI.life davon abzuhalten, Sie zu Marketingzwecken zu kontaktieren oder Ihre Daten an andere Mitglieder des Unternehmens EmpathicAI.life weiterzugeben. Wenn Sie nicht mehr zu Marketingzwecken kontaktiert werden möchten, können Sie die Änderungen in Ihrem Profil auf unserer Website vornehmen",
            },
            section7: {
              heading: "Welche Rechte haben Sie in Bezug auf Ihre Daten?",
              part1:
                "möchte sicherstellen, dass Sie sich über alle Ihre Datenschutzrechte im Klaren sind. Jeder Nutzer hat das Recht auf Folgendes:",
              part1_1:
                "Das Recht auf Auskunft - Sie haben das Recht, Vitafluence.ai um Kopien Ihrer persönlichen Daten zu bitten.",
              part1_2:
                "Das Recht auf Berichtigung - Sie haben das Recht, von Vitafluence.ai die Berichtigung von Daten zu verlangen, von denen Sie glauben, dass sie unzutreffend sind. Sie haben auch das Recht, Vitafluence.ai aufzufordern, die Informationen, die Sie für unvollständig halten, zu vervollständigen.",
              part1_3:
                "Das Recht auf Löschung - Sie haben das Recht zu verlangen, dass Vitafluence.ai Ihre personenbezogenen Daten unter bestimmten Bedingungen löscht.",
              part1_4:
                "Recht auf Einschränkung der Verarbeitung - Sie haben das Recht zu verlangen, dass Vitafluence.ai die Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Bedingungen einschränkt.",
              part1_5:
                "Recht auf Widerspruch gegen die Verarbeitung - Sie haben das Recht, der Verarbeitung Ihrer personenbezogenen Daten durch Vitafluence.ai unter bestimmten Bedingungen zu widersprechen. ",
              part1_6:
                "Das Recht auf Datenübertragbarkeit - Sie haben das Recht zu verlangen, dass Vitafluence.ai die von uns gesammelten Daten unter bestimmten Bedingungen an eine andere Organisation oder direkt an Sie übermittelt.",
              part2:
                "Wenn Sie einen Antrag stellen, haben wir einen Monat Zeit, um Ihnen zu antworten. Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte per E-Mail: ",
            },
            section8: {
              heading: "Cookies",
              part1:
                "Cookies sind Textdateien, die auf Ihrem Computer abgelegt werden, um Standard-Internetprotokolldaten und Informationen zum Besucherverhalten zu sammeln. Wenn Sie unsere Websites besuchen, können wir über Cookies oder ähnliche Technologien automatisch Informationen von Ihnen erfassen. Weitere Informationen finden Sie unter ",
              part2:
                "EmpathicAI.life verwendet Cookies auf verschiedene Weise, um Ihre Erfahrung auf unserer Website zu verbessern, einschließlich:",
              part2_1: "Sie bleiben angemeldet.",
              part2_2: "Verstehen, wie Sie unsere Website nutzen.",
            },
            section9: {
              heading: "Welche Arten von Cookies nutzen wir?",
              part1:
                "Es gibt eine Reihe verschiedener Arten von Cookies, aber unsere Website verwendet diese:",
              part1_1:
                "Funktionalität - Vitafluence.ai verwendet diese Cookies, damit wir Sie auf unserer Website erkennen und uns Ihre zuvor gewählten Einstellungen merken. Dazu gehören beispielsweise die von Ihnen bevorzugte Sprache und der Ort, an dem Sie sich befinden. Es wird eine Mischung aus Erstanbieter- und Drittanbieter-Cookies verwendet.",
              part1_2:
                "Nutzerverhalten - Vitafluence.ai verwendet diese Cookies, um Informationen über Ihren Besuch auf unserer Website, die von Ihnen angesehenen Inhalte, die von Ihnen verfolgten Links und Informationen über Ihren Browser, Ihr Gerät und Ihre IP-Adresse zu sammeln.",
            },
            section10: {
              heading: "Cookies verwalten",
              part1:
                "Sie können Ihren Browser so einstellen, dass er keine Cookies annimmt, und auf der oben genannten Website (allaboutcookies.org) erfahren Sie, wie Sie Cookies aus Ihrem Browser entfernen können. In einigen Fällen kann es jedoch vorkommen, dass einige Funktionen unserer Website dann nicht mehr funktionieren.",
            },
            section11: {
              heading: "Datenschutzrichtlinien anderer Websites",
              part1:
                "Unsere Website kann Links zu und von Websites Dritter enthalten. Wenn Sie einem Link zu einer dieser Websites folgen, beachten Sie bitte, dass diese Websites ihre eigenen Datenschutzrichtlinien haben und dass wir keine Verantwortung oder Haftung für diese Richtlinien übernehmen. Bitte überprüfen Sie diese Richtlinien, bevor Sie persönliche Daten an diese Websites übermitteln.",
            },
            section12: {
              heading: "Änderungen an unserer Datenschutzrichtlinie",
              part1:
                "Vitafluence.ai überprüft seine Datenschutzpolitik regelmäßig. Alle Änderungen, die wir in Zukunft an unserer Datenschutzpolitik vornehmen, werden auf dieser Seite veröffentlicht und Ihnen gegebenenfalls per E-Mail mitgeteilt. Bitte besuchen Sie diese Seite regelmäßig, um sich über eventuelle Aktualisierungen oder Änderungen unserer Datenschutzrichtlinien zu informieren. Diese Datenschutzrichtlinie wurde zuletzt am 22. Oktober 2024 aktualisiert.",
            },
            section13: {
              heading: "Kontakt zu EmpathicAI.life",
              part1:
                "Wenn Sie Fragen zur Datenschutzerklärung von EmpathicAI.life haben, zu den Daten, die wir über Sie speichern, oder wenn Sie eines Ihrer Datenschutzrechte ausüben möchten, zögern Sie bitte nicht, uns zu kontaktieren.",
              part2: "Rufen Sie uns an: ",
              part3: "Oder schreiben Sie uns an: ",
            },
            section14: {
              heading: "Kontakt zur zuständigen Behörde",
              part1:
                "Wenn Sie eine Beschwerde einreichen möchten oder der Meinung sind, dass EmpathicAI.life Ihr Anliegen nicht zufriedenstellend behandelt hat, können Sie sich an das Büro des Datenschutzbeauftragten wenden.",
            },
          },
        },
      },
    },
  });

export default i18n;
