import { useTranslation } from "react-i18next";

import { Heading, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { TeamMemberProps, useTeamMembers } from "./helpers";
import TeamMember from "./TeamMember";

function Team() {
  // Hook
  const { t } = useTranslation();
  const members = useTeamMembers();

  return (
    <Flex
      direction={"column"}
      h={"fit-content"}
      width={"85%"}
      mx={"auto"}
      my={{ lg: "80px", xl: "120px", "2xl": "140px" }}
    >
      {/* sub-heading */}
      <Text
        fontFamily={"Poppins, sans-serif"}
        fontWeight={"600"}
        bgGradient={`linear(to right, #641ba3 0%, #2088be 100%)`}
        bgClip={"text"}
        color={"transparent"}
        textTransform={"uppercase"}
        letterSpacing={"1px"}
        fontSize={{ lg: "14px", "2xl": "16px" }}
        lineHeight={1}
        w={"fit-content"}
      >
        {t("team.subHeading")}
      </Text>

      {/* Section Heading */}
      <Heading
        as={"h2"}
        fontFamily={"Poppins, sans-serif"}
        color={"#202053"}
        fontSize={{ lg: "48px", xl: "56px" }}
        fontWeight={"800"}
        letterSpacing={"-2.68359px"}
        lineHeight={{ lg: "66px", "2xl": "76px" }}
      >
        {t("team.heading")}
      </Heading>

      {/* Section Content */}
      <SimpleGrid
        mt={"80px"}
        templateColumns={{
          lg: "repeat(auto-fill, minmax(310px, 1fr))",
          xl: "repeat(auto-fill, minmax(280px, 1fr))",
          "2xl": "repeat(auto-fill, minmax(260px, 1fr))",
        }}
        spacing={6}
        p={1}
      >
        {members?.map((m: TeamMemberProps, index: number) => (
          <TeamMember
            key={index}
            tintColor={"#323383"}
            imgSrc={m?.imgSrc}
            name={m?.name}
            position={m?.position}
            social={m?.social}
            bio={m?.bio}
            academic={m?.academic}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
}

export default Team;
