import { Text } from "@chakra-ui/react";

interface NodeHeadingtProps {
  text: string;
  inGraph?: boolean;
  startColor?: string;
  stopColor?: string;
}

export function NodeHeading({
  text,
  inGraph,
  startColor = "#323383",
  stopColor = "#2088be",
}: NodeHeadingtProps) {
  return (
    <Text
      bgGradient={`linear(to right, ${startColor} 0%, ${stopColor} 100%)`}
      bgClip={inGraph ? "text" : undefined}
      color={inGraph ? "transparent" : "white"}
      p={2}
      py={inGraph ? 2 : 1.5}
      borderRadius={inGraph ? 0 : "18px"}
      fontFamily={"Poppins, sans-serif"}
      lineHeight={inGraph ? "1.3" : "1"}
      fontSize={{
        lg: inGraph ? "14px" : "12px",
        xl: inGraph ? "16px" : "14px",
        "2xl": inGraph ? "18px" : "16px",
      }}
      fontWeight={inGraph ? "600" : "400"}
      w={"fit-content"}
    >
      {text}
    </Text>
  );
}
