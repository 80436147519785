import { useState, useEffect, useRef } from "react";
import { Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";

import AnimatedButton from "shared/AnimatedButton";
import { NodeHeading } from "./NodeHeading";
import Node from "./Node";

import { nodes, useBrands } from "./helpers";
import { useTranslation } from "react-i18next";

function Brands() {
  // State
  const [activeSection, setActiveSection] = useState("");

  // Hook
  const sectionsRef = useRef<(HTMLDivElement | null)[]>([]);
  const sections = useBrands();
  const { t } = useTranslation();

  // Handlers
  function scrollToSection(idx: number) {
    const section = sectionsRef?.current[idx];

    if (section) {
      section.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }

  function getText(index: number) {
    return index === 1
      ? "stealth1"
      : index === 2
      ? "stealth2"
      : index === 8
      ? "stealth3"
      : index === 9
      ? "stealth4"
      : nodes[index]?.name ?? "";
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionText = entry.target
              .getAttribute("data-text")
              ?.toLowerCase();
            if (sectionText) {
              setActiveSection(sectionText);
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <Flex
      direction={"column"}
      h={"fit-content"}
      width={"85%"}
      mx={"auto"}
      my={{ lg: "80px", xl: "120px", "2xl": "140px" }}
      mt={{ lg: "120px", xl: "160px", "2xl": "180px" }}
    >
      {/* Section Heading */}
      <Heading
        as={"h2"}
        fontFamily={"Poppins, sans-serif"}
        color={"#202053"}
        fontSize={{ lg: "48px", xl: "56px" }}
        fontWeight={"800"}
        letterSpacing={"-2.68359px"}
        lineHeight={{ lg: "66px", "2xl": "76px" }}
        mb={"-65px"}
      >
        {t("brands.heading")}
      </Heading>

      {/* Section Content */}
      <Flex gap={4} position={"relative"} h={"fit-content"} w={"100%"}>
        <Flex
          direction={"column"}
          align={"center"}
          justify={"center"}
          w={{ lg: "40%", xl: "50%" }}
        >
          {sections?.map((s, index) => {
            const hasTextDetails = s?.text && s?.text?.length > 0;
            const hasExternalLink = s?.url && s?.url?.length > 0;

            return (
              <Flex
                key={s?.name}
                direction={"column"}
                justify={"center"}
                h={"min(700px, 100vh)"}
                w={"100%"}
                gap={2}
                ref={(el) => (sectionsRef.current[index] = el)}
                data-text={s?.name}
              >
                <NodeHeading
                  text={s?.name}
                  startColor={s?.startColor}
                  stopColor={s?.stopColor}
                />
                <Text
                  w={{ lg: "90%", xl: "85%" }}
                  fontFamily={"Poppins, sans-serif"}
                  fontWeight={"600"}
                  fontSize={{ lg: "32px", xl: "46px" }}
                  color={"gray.700"}
                  lineHeight={1.2}
                >
                  {s?.heading}
                </Text>
                {hasTextDetails && (
                  <Flex
                    direction={"column"}
                    gap={2}
                    w={{ lg: "90%", xl: "85%" }}
                    color={"#425466"}
                    mt={4}
                  >
                    {s?.text?.split("\n")?.map((line, index) => (
                      <Text key={index}>{line}</Text>
                    ))}
                  </Flex>
                )}

                {hasExternalLink && (
                  <AnimatedButton
                    bg={"transparent"}
                    color={"gray.600"}
                    fontFamily="Poppins, sans-serif"
                    fontWeight={"500"}
                    fontSize={"14px"}
                    h={"fit-content"}
                    w={"fit-content"}
                    p={3}
                    px={4}
                    mt={10}
                    borderRadius={"30px"}
                    borderWidth={1}
                    borderColor={"gray.600"}
                    _hover={{
                      bg: "transparent",
                      borderColor: "#2088be",
                      color: "#2088be",
                    }}
                    _focus={{
                      bg: "transparent",
                      borderColor: "#0a2540",
                      color: "#0a2540",
                    }}
                    onClick={() => window.open(s?.url, "_blank")}
                  >
                    {t("brands.cta")}
                  </AnimatedButton>
                )}
              </Flex>
            );
          })}
        </Flex>

        {/* graph section */}
        <Flex
          w={{ lg: "60%", xl: "50%" }}
          h={"100vh"}
          py={"auto"}
          align={"center"}
          justify={"center"}
          position={"sticky"}
          top={0}
          bottom={0}
        >
          <Flex
            align={"center"}
            justify={"center"}
            p={2}
            h={"fit-content"}
            w={"fit-content"}
          >
            <SimpleGrid
              columns={4}
              columnGap={"40px"}
              rowGap={"80px"}
              templateRows={"repeat(3, auto)"}
              templateColumns={"repeat(4, 90px)"}
              alignItems={"center"}
              justifyItems={"center"}
            >
              {Array.from({ length: 11 }).map((_, index) => (
                <Node
                  key={index}
                  isCentral={nodes[index]?.name?.toLowerCase() === "empathicai"}
                  text={getText(index)}
                  altSrc={nodes[index]?.altSrc}
                  src={nodes[index]?.src}
                  isActive={activeSection === nodes[index]?.name?.toLowerCase()}
                  onClick={() =>
                    // scroll to correspondent section
                    scrollToSection(
                      sections.findIndex((s) => s?.name === nodes[index]?.name)
                    )
                  }
                />
              ))}
            </SimpleGrid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Brands;
