import { Button, Icon, useTheme } from "@chakra-ui/react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { FaPlus } from "react-icons/fa";

interface ToggleButtonProps extends HTMLMotionProps<"button"> {
  showDetail: boolean;
  tintColor: string;
}

const MotionButton = motion(Button);

function ToggleButton({ showDetail, tintColor, ...props }: ToggleButtonProps) {
  // Theme
  const { colors } = useTheme();

  const animationVariants = {
    main: {
      rotate: 0,
      color: colors.gray[600],
      transition: { duration: 0.1 },
    },
    detail: {
      rotate: 45,
      color: tintColor,
      transition: { duration: 0.1 },
    },
  };

  return (
    <MotionButton
      h={"fit-content"}
      w={"fit-content"}
      minW={"fit-content"}
      p={2}
      borderRadius={"50%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      zIndex={2}
      variants={animationVariants}
      transition={{ bounce: 0 }}
      initial={false}
      animate={showDetail ? "detail" : "main"}
      {...props}
    >
      <Icon boxSize={"10px"} as={FaPlus} />
    </MotionButton>
  );
}

export default ToggleButton;
