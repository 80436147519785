import { Flex, Image, Text } from "@chakra-ui/react";

interface CardProps {
  icon: string;
  heading: string;
  text: string;
  tintColor: string;
  color: string;
}

function Card({ icon, heading, text, color, tintColor }: CardProps) {
  return (
    <Flex
      gap={4}
      bg={"rgba(23, 74, 228, 0.08)"}
      p={3}
      pr={6}
      borderRadius={"12px"}
      w={"max-content"}
    >
      {/* icon */}
      <Flex
        borderRadius={"12px"}
        minW={"48px"}
        maxW={"48px"}
        h={"48px"}
        bg={color}
        align={"center"}
        justify={"center"}
      >
        <Image src={icon} p={2} w={"100%"} h={"100%"} />
      </Flex>

      {/* heading & text */}
      <Flex direction={"column"} gap={1} w={"100%"}>
        <Text
          fontFamily={"Poppins, sans-serif"}
          fontWeight={"600"}
          color={color}
          w={"fit-content"}
          fontSize={{ lg: "14px", "2xl": "16px" }}
        >
          {heading}
        </Text>
        <Text
          maxW={{ lg: "23ch", xl: "30ch" }}
          color={tintColor}
          fontSize={{ lg: "14px", "2xl": "16px" }}
          lineHeight={1.4}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  );
}

export default Card;
