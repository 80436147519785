import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Card from "./Card";

import icon1 from "assets/home/mission/icon1.svg";
import icon2 from "assets/home/mission/icon2.svg";
import icon3 from "assets/home/mission/icon3.svg";
import handsImg2 from "assets/home/mission/hands-robot-human2.png";

function Mission() {
  // Hooks
  const { t, i18n } = useTranslation();
  const imgSize = useBreakpointValue({ lg: "180px", xl: "230px" }) ?? "230px"; // fallback value: "230px", imgSize initially undefined
  const svgSize = useBreakpointValue({ lg: "200px", xl: "250px" }) ?? "250px";
  const divSize = useBreakpointValue({ lg: "205px", xl: "255px" }) ?? "255px";

  return (
    <Flex
      direction={"column"}
      h={"fit-content"}
      width={"85%"}
      mx={"auto"}
      my={{ lg: "80px", xl: "120px", "2xl": "140px" }}
    >
      {/* sub-heading */}
      <Text
        fontFamily={"Poppins, sans-serif"}
        fontWeight={"600"}
        bgGradient={`linear(to right, #641ba3 0%, #2088be 100%)`}
        bgClip={"text"}
        color={"transparent"}
        textTransform={"uppercase"}
        letterSpacing={"1px"}
        fontSize={{ lg: "14px", "2xl": "16px" }}
        lineHeight={1}
        w={"fit-content"}
      >
        {t("mission.subHeading")}
      </Text>

      {/* Section Heading */}
      <Heading
        as={"h2"}
        fontFamily={"Poppins, sans-serif"}
        color={"#202053"}
        fontSize={{ lg: "48px", xl: "56px" }}
        fontWeight={"800"}
        letterSpacing={"-2.68359px"}
        lineHeight={{ lg: "66px", "2xl": "76px" }}
      >
        {t("mission.heading")}
      </Heading>

      {/* Section Content */}
      <Flex w={"100%"} h={"fit-content"} position={"relative"} py={"30px"}>
        <Flex
          position={"relative"}
          justify={"center"}
          align={"center"}
          w={divSize}
          h={divSize}
          mt={"80px"}
          mx={"auto"}
          p={2}
        >
          {/* card 1 */}
          <Flex
            position={"absolute"}
            left={"-80%"}
            transform={"translateX(-50%)"}
          >
            <Card
              tintColor={"#23245c"}
              color={"#641ba3"}
              icon={icon1}
              heading={t("mission.card1.heading")}
              text={t("mission.card1.text")}
            />
          </Flex>

          {/* card 2 */}
          <Flex
            position={"absolute"}
            right={"-75%"}
            top={"-20%"}
            transform={"translate(50%, -20%)"}
          >
            <Card
              tintColor={"#23245c"}
              color={"#2088be"}
              icon={icon2}
              heading={t("mission.card2.heading")}
              text={t("mission.card2.text")}
            />
          </Flex>

          {/* card 3 */}
          <Flex
            position={"absolute"}
            right={{ lg: "-40%", xl: "-50%" }}
            bottom={{ lg: "-95%", xl: "-75%" }}
            transform={`translate(50%, ${
              i18n?.language === "en" ? "-50%" : "-10%"
            })`}
          >
            <Card
              tintColor={"#23245c"}
              color={"#323383"}
              icon={icon3}
              heading={t("mission.card3.heading")}
              text={t("mission.card3.text")}
            />
          </Flex>

          {/* human & robot hands */}
          <Image
            position={"absolute"}
            src={handsImg2}
            alt={"robot & human hands"}
            w={"460px"}
            h={"460px"}
            style={{ width: imgSize, height: imgSize }}
          />

          {/* animated dashed ring */}
          <motion.svg
            width={svgSize}
            height={svgSize}
            fill={"rgba(23, 74, 228, 0.015)"}
            viewBox={"0 0 506 506"}
            xmlns={"http://www.w3.org/2000/svg"}
          >
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#641ba3" />
                <stop offset="100%" stopColor="#2088be" />
              </linearGradient>
            </defs>
            <motion.circle
              cx={"250"}
              cy={"250"}
              r={"247"}
              stroke="url(#gradient)"
              strokeWidth={4}
              strokeLinecap={"round"}
              strokeLinejoin={"round"}
              initial={{ strokeDasharray: "24 10 0 0" }}
              animate={{
                strokeDasharray: ["15 120 25 25", "16 25 92 72", "4 250 22 22"],
                rotate: [120, 360],
              }}
              transition={{
                duration: 8,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </motion.svg>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Mission;
