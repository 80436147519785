import { useTranslation } from "react-i18next";

import member1Img from "assets/home/team/peter-owotoki.png";
import member2Img from "assets/home/team/mary-mcdavid.jpg";
import member3Img from "assets/home/team/martin-dober.png";
import member4Img from "assets/home/team/wamuyu-owotoki.png";
import member5Img from "assets/home/team/christian-weiss.jpg";
import womanFallbackImg from "assets/home/team/woman-fallback.png";

export interface TeamMemberProps {
  name: string;
  position: string;
  bio: string;
  academic: string;
  social?: string;
  imgSrc: string;
}

export function useTeamMembers() {
  // Hook
  const { t } = useTranslation();

  const members: TeamMemberProps[] = [
    {
      name: "Dr Peter Owotoki",
      position: t("team.member1.position"),
      bio: t("team.member1.bio"),
      academic: t("team.member1.academic"),
      social: "https://www.linkedin.com/in/peter-owotokidr-ing/",
      imgSrc: member1Img,
    },
    {
      name: "Mary McDavid",
      position: t("team.member2.position"),
      bio: t("team.member2.bio"),
      academic: t("team.member2.academic"),
      social: "https://www.linkedin.com/in/mary-mcdavid-450526271/",
      imgSrc: member2Img,
    },
    {
      name: "Martin Dober",
      position: t("team.member3.position"),
      bio: t("team.member3.bio"),
      academic: t("team.member3.academic"),
      imgSrc: member3Img,
      social: "https://www.linkedin.com/in/dober-martin/",
    },
    {
      name: "Dr Tania Chilima",
      position: t("team.member4.position"),
      bio: t("team.member4.bio"),
      academic: t("team.member4.academic"),
      imgSrc: womanFallbackImg,
      social: "https://www.linkedin.com/in/tania-pereira-chilima-53983395/",
    },
    {
      name: "Dr Christian Weiss",
      position: t("team.member5.position"),
      bio: t("team.member5.bio"),
      academic: t("team.member5.academic"),
      imgSrc: member5Img,
      social: "https://www.linkedin.com/in/drcweiss/",
    },
    {
      name: "Dr Wamuyu Owotoki",
      position: t("team.member6.position"),
      bio: t("team.member6.bio"),
      academic: t("team.member6.academic"),
      imgSrc: member4Img,
      social: "https://www.linkedin.com/in/wamuyu/",
    },
  ];

  return members;
}
