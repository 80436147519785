import { useState } from "react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { Button, Flex, Icon, Image, Text, useTheme } from "@chakra-ui/react";

import Details from "./Details";

import { FaLinkedin } from "react-icons/fa";
import ToggleButton from "./ToggleButton";
import { useTranslation } from "react-i18next";

const MotionFlex = motion(Flex);

interface Props extends HTMLMotionProps<"div"> {
  tintColor?: string;
  name: string;
  imgSrc: string;
  position?: string;
  bio: string;
  academic: string;
  social?: string;
}

function TeamMember({
  tintColor = "#000000",
  name,
  position,
  bio,
  academic,
  imgSrc,
  social,
  ...props
}: Props) {
  // State
  const [showDetail, setShowDetail] = useState(false);

  // Theme
  const { colors } = useTheme();

  // Hook
  const { i18n } = useTranslation();

  // Handler
  const goToUrl = (url: string) => {
    window.open(url, "_blank");
  };

  const animationVariants = {
    main: { borderTopColor: colors.gray[200], transition: { delay: 0.3 } },
    detail: { color: "#ffffff" },
  };

  const hasSocialProfile = social && social?.length > 0;

  return (
    <MotionFlex
      h={i18n?.language === "en" ? "360px" : "420px"}
      position={"relative"}
      bg={"#F9FAFC"}
      borderRadius={"12px"}
      p={4}
      pt={8}
      overflow={"hidden"}
      direction={"column"}
      align={"center"}
      {...props}
    >
      <Image
        src={imgSrc}
        alt={`${name} image`}
        w="45%"
        objectFit={"cover"}
        borderRadius={"50%"}
        mx={"auto"}
      />
      <Text
        mt={8}
        as={"h2"}
        fontWeight={"500"}
        position={"relative"}
        fontSize={"18px"}
        color={"gray.700"}
      >
        {name}
      </Text>

      <Text
        mt={2}
        fontSize={"14px"}
        color={"#202053"}
        opacity={0.7}
        fontFamily={"Poppins, sans-serif"}
        textAlign={"center"}
        maxW={"80%"}
        lineHeight={"1.35"}
      >
        {position}
      </Text>

      <MotionFlex
        w={"calc(100% - 30px)"}
        justify={"space-between"}
        align={"center"}
        position={"absolute"}
        bottom={2}
        py={2}
        borderTopWidth={1}
        initial={false}
        animate={showDetail ? "detail" : "main"}
        variants={animationVariants}
        zIndex={2}
      >
        <Button
          bg={"transparent"}
          _hover={{
            bg: "transparent",
            color: showDetail ? "#0077B5" : tintColor,
          }}
          _focus={{
            bg: "transparent",
            color: showDetail ? "#0077B5" : tintColor,
          }}
          color={showDetail ? "white" : "#0077B5"}
          transition={"all 0.3s ease"}
          onClick={() => !!hasSocialProfile && goToUrl(social)}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          fontSize={"14px"}
          p={1}
          h={"fit-content"}
          borderRadius={0}
        >
          <Icon boxSize={4} as={FaLinkedin} />
          <Text>LinkedIn</Text>
        </Button>

        <ToggleButton
          showDetail={showDetail}
          tintColor={tintColor}
          onClick={() => setShowDetail((s) => !s)}
        />
      </MotionFlex>
      <AnimatePresence>
        {showDetail && (
          <Details tintColor={tintColor} bio={bio} academic={academic} />
        )}
      </AnimatePresence>
    </MotionFlex>
  );
}

export default TeamMember;
