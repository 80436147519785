import { Heading, Link, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoginForm from "components/auth/login/LoginForm";

const LoginPage = () => {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Heading size="md">{t("auth.login.subHeading")}</Heading>
      <Heading size="lg">{t("auth.login.heading")}</Heading>
      <LoginForm />
      <Text>
        {t("auth.login.text")}{" "}
        <Link color="blue.500" onClick={() => navigate("/register")}>
          {t("auth.login.link")}
        </Link>
      </Text>
    </>
  );
};

export default LoginPage;
