import {
  Box,
  Flex,
  Image,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ContactList } from "./helpers";
import { FaLink, FaMapMarkerAlt } from "react-icons/fa";
import { FaFax } from "react-icons/fa6";
import { MdCall, MdEmail } from "react-icons/md";

export default function TableDesktop() {
  const tableHeaders = ["COUNTRY", "INSTITUTION", "CONTACT INFORMATION"];
  const contactList = ContactList.sort((a, b) =>
    a.name.title.localeCompare(b.name.title)
  );

  // Handler
  const goToUrl = (url: string | undefined) => {
    if (url) window.open(url, "_blank");
  };

  return (
    <Table variant={"simple"} size="lg" w={"100%"}>
      <Thead>
        <Tr>
          {tableHeaders.map((col, index) => {
            return (
              <Th
                key={"table-header-" + index}
                color={"highlight.primary"}
                borderColor={"whiteAlpha.900"}
              >
                {col}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {contactList.map((list, index) => {
          return (
            <Tr key={"table-row" + index}>
              {/* Country Name */}
              <Td borderColor={"whiteAlpha.900"}>
                <Flex direction={"column"}>
                  <Image
                    src={`https://flagcdn.com/w320/${list.name.code}.png`}
                    alt={`${list.name.title} Flag`}
                    w={"42px"}
                    h={"auto"}
                  />
                  <Text pt={2} fontSize={"18px"} color={"highlight.primary"}>
                    {list.name.title}
                  </Text>
                </Flex>
              </Td>
              {/* Instituition */}
              <Td borderColor={"whiteAlpha.900"} w={"100%"} maxW={"320px"}>
                <Box whiteSpace="normal" wordBreak="break-word">
                  {list.institution.title}
                </Box>
                {list.institution.website.length > 0 && (
                  <Box
                    whiteSpace="normal"
                    wordBreak="break-word"
                    flexDirection={"row"}
                  >
                    <Flex direction={"row"} align={"center"}>
                      <FaLink />
                      <Link
                        pl={2}
                        color="blue.500"
                        cursor={"pointer"}
                        onClick={() => goToUrl(list.institution.websiteLink)}
                      >
                        {list.institution.website}
                      </Link>
                    </Flex>
                  </Box>
                )}
                {list.institution.location.length > 0 && (
                  <Box whiteSpace="normal" wordBreak="break-word">
                    <Flex direction={"row"} align={"center"}>
                      <FaMapMarkerAlt />
                      <Text pl={2}>{list.institution.location}</Text>
                    </Flex>
                  </Box>
                )}
              </Td>
              {/* Contact Info */}
              <Td borderColor={"whiteAlpha.900"}>
                {list.contact.email.map((email, index) => {
                  return (
                    <Box key={"email" + index}>
                      <Flex direction={"row"} align={"center"}>
                        <MdEmail />
                        <Text
                          pl={2}
                          as={"a"}
                          color={"blue.500"}
                          href={`mailto:${email}`}
                        >
                          {email}
                        </Text>
                      </Flex>
                    </Box>
                  );
                })}
                {list.contact.tel.map((phone, index) => {
                  return (
                    <Box key={"tel" + index}>
                      <Flex direction={"row"} align={"center"}>
                        <MdCall />
                        <Text
                          pl={2}
                          as={"a"}
                          color={"blue.500"}
                          href={`tel:${phone}`}
                        >
                          {phone}
                        </Text>
                      </Flex>
                    </Box>
                  );
                })}
                {list.contact.fax.map((fax, index) => {
                  return (
                    <Box key={"fax" + index}>
                      <Flex direction={"row"} align={"center"}>
                        <FaFax />
                        <Text
                          pl={2}
                          as={"a"}
                          color={"blue.500"}
                          href={`tel:${fax}`}
                        >
                          {fax}
                        </Text>
                      </Flex>
                    </Box>
                  );
                })}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
      <Tfoot>
        <Tr>
          {tableHeaders.map((col, index) => {
            return (
              <Th
                key={"table-footer-" + index}
                color={"highlight.primary"}
                borderColor={"whiteAlpha.900"}
              >
                {col}
              </Th>
            );
          })}
        </Tr>
      </Tfoot>
    </Table>
  );
}
