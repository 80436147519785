import { Flex, TableContainer, useMediaQuery } from "@chakra-ui/react";
import TableDesktop from "./TableDesktop";
import TableMobile from "./TableMobile";

export default function ContactsTable() {
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Flex
      direction={"column"}
      position="relative"
      bg={"transparent"}
      w={"100%"}
      pb={"64px"}
    >
      <TableContainer>
        {isMobileView ? <TableMobile /> : <TableDesktop />}
      </TableContainer>
    </Flex>
  );
}
