import { Flex, Heading } from "@chakra-ui/react";
import AnimatedButton from "shared/AnimatedButton";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header() {
  // Hook
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <Flex position={"relative"} w={"100%"} h={"100vh"}>
      <Flex position={"absolute"} inset={0}>
        {/* gradient overlay */}
        <Flex
          position={"absolute"}
          inset={0}
          w={"100%"}
          h={"100%"}
          background={"linear-gradient(to top, #6985c1 30%, transparent)"}
          opacity={"0.5"}
          zIndex={1}
        />

        {/* landing video */}
        <Flex
          as={"video"}
          w={"100%"}
          h={"100%"}
          src={
            "https://empathicai.s3.eu-central-1.amazonaws.com/human_robot_hand_video.webm"
          }
          objectFit={"cover"}
          objectPosition={"top center"}
          autoPlay
          muted
          loop
        />
      </Flex>

      {/* typewriter-effect content */}
      <Flex
        direction={"column"}
        gap={10}
        align={"center"}
        justify={"center"}
        zIndex={1}
        w={"100%"}
        h={"100%"}
      >
        <Flex align={"center"} gap={4} justify={"center"} mx={"auto"}>
          <Flex
            pt={2}
            pb={6}
            px={8}
            boxShadow={"0 8px 32px 0 rgba(31.51, 32.14, 82.55, 0.2)"}
            backdropFilter={"blur(6px)"}
            borderRadius={"10px"}
          >
            <Heading
              fontSize={{ lg: "48px", xl: "52px" }}
              as={"h1"}
              textAlign={"center"}
              mx={"auto"}
              color={"#323383"}
            >
              {i18n.language === "en" ? (
                <TypeWriterHeaderEN />
              ) : (
                <TypeWriterHeaderDE />
              )}
            </Heading>
          </Flex>
        </Flex>
      </Flex>

      {/* action button */}
      <Flex
        justify={"center"}
        align={"center"}
        w={"fit-content"}
        position={"absolute"}
        mx={"auto"}
        bottom={"min(20%, 100px)"}
        left={"50%"}
        transform={"translateX(-50%)"}
        zIndex={1}
      >
        {/* Contact Us Button */}
        <AnimatedButton
          inHeader
          w={"180px"}
          size={{ lg: "md", xl: "lg" }}
          color={"white"}
          bg={"#323383"}
          py={5}
          boxShadow={"0px 4px 9px 0px #00000040"}
          _hover={{ bg: "#232F41" }}
          _focus={{ bg: "#232F41" }}
          onClick={() => navigate("/investor-relations")}
        >
          {t("header.cta")}
        </AnimatedButton>
      </Flex>
    </Flex>
  );
}

// NOTE: limitation in react-type-animation: `sequence` cannot be updated dynamically
// no matter the logic applied => need to create 2 components <...DE /> & <...EN />
function TypeWriterHeaderDE() {
  const { t } = useTranslation();

  return (
    <TypeAnimation
      sequence={[
        t("header.seq1"),
        2000,
        t("header.seq2"),
        1000,
        t("header.seq3"),
        1000,
        t("header.seq4"),
        3000,
        t("header.seq5"),
        3000,
      ]}
      speed={60}
      repeat={Infinity}
      cursor={false}
    />
  );
}

function TypeWriterHeaderEN() {
  const { t } = useTranslation();

  return (
    <TypeAnimation
      sequence={[
        t("header.seq1"),
        2000,
        t("header.seq2"),
        1000,
        t("header.seq3"),
        1000,
        t("header.seq4"),
        3000,
        t("header.seq5"),
        3000,
      ]}
      speed={60}
      repeat={Infinity}
      cursor={false}
    />
  );
}
export default Header;
