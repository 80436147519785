import * as React from "react";
import * as ReactDOM from "react-dom/client";
import AppRoutes from "./routes";
import { pdfjs } from "react-pdf";
import "./i18n";

// store
import { store } from "./store/store";
import { Provider } from "react-redux";

import "./index.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <AppRoutes />
    </React.StrictMode>
  </Provider>
);
