import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  Flex,
  List,
  ListItem,
  Button,
} from "@chakra-ui/react";

import Bullet from "components/ui/Bullet";
import AccordionHeader from "components/ui/AccordionHeader";
import { useNavigate } from "react-router-dom";

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PrivacyPolicyModal({
  isOpen,
  onClose,
}: PrivacyPolicyModalProps) {
  // Hook
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        bg={"background"}
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "90%", lg: "800px" }}
        maxW={{ base: "90%", lg: "800px" }}
        h={{
          lg: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        my={"auto"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          color={"gray.700"}
          fontFamily={"Poppins, sans-serif"}
          justifyContent={"space-between"}
          borderBottomWidth={1}
          borderBottomColor={"gray.200"}
        >
          {/* header */}
          <Text fontSize={"16px"} fontWeight={"500"}>
            {t("auth.privacy")}
          </Text>
        </ModalHeader>

        <ModalBody py={6}>
          <Accordion allowToggle defaultIndex={[0]}>
            {/* Introduction */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section1.heading")} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    {t("policy.section1.part1")}
                    <Text
                      as={"a"}
                      color={"blue.500"}
                      href="mailto:connect@empathicai.life"
                    >
                      connect@empathicai.life
                    </Text>
                  </Text>
                  <Text>{t("policy.section1.part2")}</Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* What data do we collect? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section2.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Flex direction={"column"} gap={2}>
                    <Text>{t("policy.section2.part1")}</Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section2.part1_1")}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section2.part1_2")}
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                  <Flex direction={"column"} gap={2}>
                    <Text>{t("policy.section2.part2")}</Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section2.part2_1")}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section2.part2_2")}
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* How do we collect your data? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section3.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section3.part1")}</Text>
                <List listStyleType={"none"}>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section3.part1_1")}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section3.part1_2")}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section3.part1_3")}
                    </Text>
                  </ListItem>
                </List>
              </AccordionPanel>
            </AccordionItem>

            {/* How do we store your data? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section4.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section4.part1")}</Text>
                <List listStyleType={"none"}>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section4.part1_1")}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section4.part1_2")}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section4.part1_3")}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section4.part1_4")}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <Bullet /> {t("policy.section4.part1_5")}
                    </Text>
                  </ListItem>
                </List>
              </AccordionPanel>
            </AccordionItem>

            {/* How do we store your data? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section5.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section5.part1")}</Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Marketing */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section6.heading")} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>{t("policy.section6.part1")}</Text>
                  <Text>{t("policy.section6.part2")}</Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* What are your data protection rights? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section7.heading")} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Flex direction={"column"} gap={2}>
                    <Text>{t("policy.section7.part1")}</Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section7.part1_1")}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section7.part1_2")}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section7.part1_3")}{" "}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section7.part1_4")}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section7.part1_5")}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section7.part1_6")}
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                  <Text>
                    {t("policy.section7.part2")}
                    <Text
                      as={"a"}
                      color={"blue.500"}
                      href="mailto:connect@empathicai.life"
                    >
                      connect@empathicai.life
                    </Text>
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* How do we use cookies? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section8.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    {t("policy.section8.part1")}
                    <Text
                      as={"a"}
                      color={"blue.500"}
                      href="https://www.allaboutcookies.org/"
                      target="_blank"
                    >
                      allaboutcookies.org
                    </Text>
                  </Text>
                  <Flex direction={"column"} gap={2}>
                    <Text>{t("policy.section8.part2")}</Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section8.part2_1")}
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet /> {t("policy.section8.part2_2")}
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* What types of cookies do we use? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section9.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={2}>
                  <Text>{t("policy.section9.part1")}</Text>
                  <List listStyleType={"none"}>
                    <ListItem>
                      <Text>
                        <Bullet /> {t("policy.section9.part1_1")}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text>
                        <Bullet /> {t("policy.section9.part1_2")}
                      </Text>
                    </ListItem>
                  </List>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* How to manage cookies? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section10.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section10.part1")}</Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Privacy policies of other websites */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section11.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section11.part1")}</Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Changes to our privacy policy */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section12.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section12.part1")}</Text>
              </AccordionPanel>
            </AccordionItem>

            {/* How to contact us? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section13.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section13.part1")}</Text>
                <Text>
                  {t("policy.section13.part2")}
                  <Text as={"a"} color={"blue.500"} href="tel:+4961747838282">
                    +49 61747838282{" "}
                  </Text>
                </Text>
                <Text>
                  {t("policy.section13.part3")}
                  <Text
                    as={"a"}
                    color={"blue.500"}
                    href="mailto:connect@empathicai.life"
                  >
                    connect@empathicai.life
                  </Text>
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* How to contact the appropriate authority? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={t("policy.section14.heading")} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>{t("policy.section14.part1")}</Text>
                <Button
                  mt={4}
                  py={"10px"}
                  w={"fit-content"}
                  h={"fit-content"}
                  fontWeight={"500"}
                  colorScheme="blue"
                  borderRadius={"18px"}
                  px={{ base: 2, sm: 3 }}
                  bg={"highlight.primary"}
                  letterSpacing={"0.03rem"}
                  transition={"all .3s ease"}
                  _active={{ opacity: 1 }}
                  _hover={{ opacity: 0.8 }}
                  _focus={{ opacity: 0.8 }}
                  _focusWithin={{ opacity: 1 }}
                  onClick={() => navigate("/contacts")}
                  color={{ base: "gray.700", lg: "whiteAlpha.900" }}
                  fontSize={{ base: "12px", md: "13px", lg: "14px" }}
                >
                  View Contacts
                </Button>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
