import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import Header from "components/home/Header";
import Brands from "components/home/brands";
import BackToTop from "components/ui/BackToTop";
import Mission from "components/home/mission";
import TrustedBy from "components/home/trustedBy";
import Team from "components/home/team";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex
      direction={"column"}
      position="relative"
      bg={"transparent"}
      w={"100%"}
      pb={"64px"}
    >
      <BackToTop />

      <Header />

      <TrustedBy />

      <Mission />

      <Brands />

      <Team />
    </Flex>
  );
}
